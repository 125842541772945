import React, { useRef } from "react";

import EmailIcon from "../assets/img/email.png";
import TelIcon from "../assets/img/telephone.png";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contact" className="bg-primary text-white min-h-[732px] pt-6">
      <div className="container mx-auto">
        <h2 className="text-5xl font-extrabold mb-4 text-center">الاتصال</h2>
        <form
          className="px-8 lg:px-0 max-w-[600px] mx-auto flex flex-col space-y-6 mb-46"
          ref={form}
          onSubmit={sendEmail}
        >
          <input
            className="form-control pr-2"
            placeholder="الاسم و اللقب"
            type="text"
            name="to_name"
          />
          <input
            className="form-control pr-2"
            placeholder="البريد الالكتروني"
            type="email"
            name="from_name"
          />
          <textarea
            className="textarea pr-2"
            placeholder="محتوى الرسالة"
            name="message"
          ></textarea>
          <input
            className="btn bg-accent hover:bg-accent-hover transition-all cursor-pointer"
            type="submit"
            value="أرسل الرسالة"
          />
        </form>
        <p className="my-10 text-center">أو يمكنم الاتصال بنا على</p>
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center justify-items-center mb-6">
            <a href="mailto:Madhioubconsulting@gmail.com">
              <h3>Madhioubconsulting@gmail.com</h3>
            </a>
            <img className="w-8 h-8 mx-2" src={EmailIcon} alt="email" />
          </div>
          <div className="flex justify-center justify-items-center mb-6">
            <h3>21692406340+</h3>
            <img className="w-8 h-8" src={TelIcon} alt="tel" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
