import React from "react";

const Message = () => {
  return (
    <section
      id="message"
      className="bg-message-image bg-cover bg-center w-full h-96 p-24 brightness-75"
    >
      <h1 className="text-white lg:text-6xl text-3xl font-bold">الرسالة</h1>
      <h2 className="text-white lg:text-3xl text-lg mt-5">
        هدفنا تقديم الإستشارة القانونية السليمة
        <br className="lg:hidden" /> من أجل الصحة الشاملة للمواطن
        <br className="lg:hidden" /> و استدامة المؤسسة و تقدم مجتمعنا
      </h2>
    </section>
  );
};

export default Message;
