import React from "react";
const Banner = () => {
  return (
    <section
      id="home"
      className="lg:h-full lg:max-h-800 bg-gradient-to-r from-yellow-200 to-yellow-400"
    >
      <div className="container mx-auto h-full lg:flex pt-32">
        <div className="flex justify-center items-center flex-col mx-auto h-full lg:text-left px-8 md:p-0">
          <h1 className="font-primary font-black text-6xl lg:text-7xl mb-4 lg:mt-[70px] leading-tight lg:leading-[90px]">
            المذيوب للإستشارات
          </h1>
          <p className="max-w-sm mx-auto mb-[50px] lg:mx-0 lg:max-w-[540px]">
            الإستشارة القانونية السليمة: وقاية و دفاع ،و إثراء للمواطن و المؤسسة
            و المجتمع.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
