import EmailIcon from "../assets/img/email.png";
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-primary-darker py-[24px] text-white text-sm text-center flex justify-center">
      <span className="mx-2">
        Madhioubconsulting@gmail.com &copy; 2023 Madhioub Consulting
      </span>
      <img className="w-4 h-4" src={EmailIcon} alt="email" />
    </footer>
  );
};

export default Footer;
