// import about icons
import Skill1 from "../src/assets/img/skills/skill1.png";
import Skill2 from "../src/assets/img/skills/skill2.png";
import Skill3 from "../src/assets/img/skills/skill3.png";
import Skill4 from "../src/assets/img/skills/skill4.png";
import Skill5 from "../src/assets/img/skills/skill5.png";

// navigation data
export const navigation = [
  {
    name: "الصفحة الرئيسية",
    href: "home",
  },
  {
    name: "الرسالة",
    href: "message",
  },
  {
    name: "أعمالنا",
    href: "skills",
  },
  {
    name: "الاتصال",
    href: "contact",
  },
];

// skills data
export const skills = [
  {
    icon: Skill1,
    title: "الإستشارة و المساعدة القانونية العامة",
  },
  {
    icon: Skill5,
    title: "النصح و الإرشاد القانوني",
  },
  {
    icon: Skill3,
    title: "تحرير العقود و الكتائب الخطية(العقود و عقود الأشغال العامة)",
  },
  {
    icon: Skill4,
    title: "إبرام المصالحات التعاقدية",
  },
  {
    icon: Skill2,
    title: "التصرف في الموارد البشرية(المسار المهني و الصحة و السلامة)",
  },
];
