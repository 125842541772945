import React from "react";
import { skills } from "../data";

const Skills = () => {
  return (
    <section id="skills" className="section bg-[#f8f3eca1 pb-32">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-extrabold mb-4">أعمالنا</h2>
        <div className="lg:grid lg:grid-cols-3 gap-12">
          {skills.map((item, index) => {
            const { icon, title } = item;
            return (
              <div
                className="flex flex-col items-center justify-center mb-16 last:mb-0 lg:mb-0"
                key={index}
              >
                <img className="mb-6 max-w-fit" src={icon} alt={title} />
                <h4 className="text-2xl mb-2 font-primary font-bold">
                  {title}
                </h4>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
